import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { slugify } from "../helpers"
import MakeAppointment from "../components/makeAppointment"

const DoctorsPage = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor {
        edges {
          node {
            id
            name
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const doctors = data.allContentfulDoctor.edges

  return (
    <Layout>
      <SEO title="Doctors" />
      <div className="mx-auto container font-sans mb-4">
        <div className="flex flex-col lg:flex-row items-center justify-around">
          <div className="flex flex-col">
            <h2 className="text-center uppercase text-xl mb-3 mt-4 mb-4 text-navy-500 font-thin">eye care doctors</h2>
            <p className="max-w-3xl mx-auto text-navy-500 px-4 text-center  mb-4 lg:mb-8" >
              U & M Family Eyecare is a comprehensive eye care center, offering complete visual and medical examinations of the eye for all ages of patients.Our doctors strive to personalize the examination based on the needs of the patient, leaving plenty of time for any questions or concerns you may have.
          </p>
            <div className="px-8 flex justify-center mb-6 lg:mb-8 flex-col items-center">
              <Link to="/locations"
                className="font-sans w-64 py-2 tracking-wide radial-gradient-green rounded shadow flex  block cursor-pointer phone items-center justify-center mb-2">
                <svg className="w-8 h-8" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
                </svg>
                <span className="pr-4 text-white font-bold text-center">Call for your consultation</span>
              </Link>
              <MakeAppointment />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center  px-2 ">
          {doctors.map(({ node: doctor }) => {
            return <Link to={'/optometrist-doctors/' + slugify(doctor.name)} key={doctor.id} aria-label={doctor.name + ' U & M Family Eye Care'}
              className="font-serif text-center text-navy-600 mx-3 mb-4 lg:mb-8 ">
              <Img className="w-64 rounded-full border-2 border-white" fluid={doctor.picture.localFile.childImageSharp.fluid} />
              <p className="font-bold">{doctor.name}</p>
            </Link>
          })}
        </div>
      </div>
    </Layout>
  )
}

export default DoctorsPage